unit Unit1;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, WEBLib.Imaging.jpeg,
  WEBLib.ExtCtrls;

type
  TForm1 = class(TForm)
    WebImageControl1: TImageControl;
    WebLabel1: TLabel;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 640;
    Height := 480;
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 160;
    WebImageControl1.Top := 64;
    WebImageControl1.Width := 330;
    WebImageControl1.Height := 244;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.AutoSize := True;
    WebImageControl1.Picture.LoadFromFile('Unit1.WebImageControl1.Picture.jpg');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 160;
    WebLabel1.Top := 368;
    WebLabel1.Width := 144;
    WebLabel1.Height := 13;
    WebLabel1.Caption := '(2022) P'#225'gina em constru'#231#227'o.';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
  finally
    WebImageControl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
  end;
end;

end.